const countries = [
    {
      name: "United Kingdom",
      code: "GB",
      zipRegex: /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/
    },
    {
      name: "Afghanistan",
      code: "AF",
      zipRegex: null
    },
    {
      name: "Albania",
      code: "AL",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Algeria",
      code: "DZ",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Andorra",
      code: "AD",
      zipRegex: /^AD\d{3}$/
    },
    {
      name: "Angola",
      code: "AO",
      zipRegex: null
    },
    {
      name: "Antigua and Barbuda",
      code: "AG",
      zipRegex: null
    },
    {
      name: "Argentina",
      code: "AR",
      zipRegex: /^[A-Z]\d{4}[A-Z]{3}$/
    },
    {
      name: "Armenia",
      code: "AM",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Australia",
      code: "AU",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Austria",
      code: "AT",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Azerbaijan",
      code: "AZ",
      zipRegex: /^AZ\d{4}$/
    },
    {
      name: "Bahamas",
      code: "BS",
      zipRegex: null
    },
    {
      name: "Bahrain",
      code: "BH",
      zipRegex: /^\d{3,4}$/
    },
    {
      name: "Bangladesh",
      code: "BD",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Barbados",
      code: "BB",
      zipRegex: /^BB\d{5}$/
    },
    {
      name: "Belarus",
      code: "BY",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Belgium",
      code: "BE",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Belize",
      code: "BZ",
      zipRegex: null
    },
    {
      name: "Benin",
      code: "BJ",
      zipRegex: null
    },
    {
      name: "Bhutan",
      code: "BT",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Bolivia",
      code: "BO",
      zipRegex: null
    },
    {
      name: "Bosnia and Herzegovina",
      code: "BA",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Botswana",
      code: "BW",
      zipRegex: null
    },
    {
      name: "Brazil",
      code: "BR",
      zipRegex: /^\d{5}-\d{3}$/
    },
    {
      name: "Brunei",
      code: "BN",
      zipRegex: /^[A-Z]{2}\d{4}$/
    },
    {
      name: "Bulgaria",
      code: "BG",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Burkina Faso",
      code: "BF",
      zipRegex: null
    },
    {
      name: "Burundi",
      code: "BI",
      zipRegex: null
    },
    {
      name: "Cambodia",
      code: "KH",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Cameroon",
      code: "CM",
      zipRegex: null
    },
    {
      name: "Canada",
      code: "CA",
      zipRegex: /^[A-Z]\d[A-Z]\s\d[A-Z]\d$/
    },
    {
      name: "Cape Verde",
      code: "CV",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Central African Republic",
      code: "CF",
      zipRegex: null
    },
    {
      name: "Chad",
      code: "TD",
      zipRegex: null
    },
    {
      name: "Chile",
      code: "CL",
      zipRegex: /^\d{7}$/
    },
    {
      name: "China",
      code: "CN",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Colombia",
      code: "CO",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Comoros",
      code: "KM",
      zipRegex: null
    },
    {
      name: "Congo",
      code: "CG",
      zipRegex: null
    },
    {
      name: "Costa Rica",
      code: "CR",
      zipRegex: /^\d{4,5}$/
    },
    {
      name: "Croatia",
      code: "HR",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Cuba",
      code: "CU",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Cyprus",
      code: "CY",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Czech Republic",
      code: "CZ",
      zipRegex: /^\d{3}\s?\d{2}$/
    },
    {
      name: "Denmark",
      code: "DK",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Djibouti",
      code: "DJ",
      zipRegex: null
    },
    {
      name: "Dominica",
      code: "DM",
      zipRegex: null
    },
    {
      name: "Dominican Republic",
      code: "DO",
      zipRegex: /^\d{5}$/
    },
    {
      name: "East Timor",
      code: "TL",
      zipRegex: null
    },
    {
      name: "Ecuador",
      code: "EC",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Egypt",
      code: "EG",
      zipRegex: /^\d{5}$/
    },
    {
      name: "El Salvador",
      code: "SV",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
      zipRegex: null
    },
    {
      name: "Eritrea",
      code: "ER",
      zipRegex: null
    },
    {
      name: "Estonia",
      code: "EE",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Ethiopia",
      code: "ET",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Fiji",
      code: "FJ",
      zipRegex: null
    },
    {
      name: "Finland",
      code: "FI",
      zipRegex: /^\d{5}$/
    },
    {
      name: "France",
      code: "FR",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Gabon",
      code: "GA",
      zipRegex: null
    },
    {
      name: "Gambia",
      code: "GM",
      zipRegex: null
    },
    {
      name: "Georgia",
      code: "GE",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Germany",
      code: "DE",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Ghana",
      code: "GH",
      zipRegex: null
    },
    {
      name: "Greece",
      code: "GR",
      zipRegex: /^\d{3}\s?\d{2}$/
    },
    {
      name: "Grenada",
      code: "GD",
      zipRegex: null
    },
    {
      name: "Guatemala",
      code: "GT",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Guinea",
      code: "GN",
      zipRegex: /^\d{3}$/
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Guyana",
      code: "GY",
      zipRegex: null
    },
    {
      name: "Haiti",
      code: "HT",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Honduras",
      code: "HN",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Hungary",
      code: "HU",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Iceland",
      code: "IS",
      zipRegex: /^\d{3}$/
    },
    {
      name: "India",
      code: "IN",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Indonesia",
      code: "ID",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Iran",
      code: "IR",
      zipRegex: /^\d{5}-\d{5}$/
    },
    {
      name: "Iraq",
      code: "IQ",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Ireland",
      code: "IE",
      zipRegex: /^[A-Z]\d{2}\s[A-Z\d]{4}$/
    },
    {
      name: "Israel",
      code: "IL",
      zipRegex: /^\d{5}(\d{2})?$/
    },
    {
      name: "Italy",
      code: "IT",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Jamaica",
      code: "JM",
      zipRegex: null
    },
    {
      name: "Japan",
      code: "JP",
      zipRegex: /^\d{3}-\d{4}$/
    },
    {
      name: "Jersey",
      code: "JE",
      zipRegex: /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/
    },
    {
      name: "Jordan",
      code: "JO",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Kazakhstan",
      code: "KZ",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Kenya",
      code: "KE",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Kiribati",
      code: "KI",
      zipRegex: null
    },
    {
      name: "Korea, Democratic People's Republic of",
      code: "KP",
      zipRegex: null
    },
    {
      name: "Korea, Republic of",
      code: "KR",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Kuwait",
      code: "KW",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Lao People's Democratic Republic",
      code: "LA",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Latvia",
      code: "LV",
      zipRegex: /^LV-\d{4}$/
    },
    {
      name: "Lebanon",
      code: "LB",
      zipRegex: /^(\d{4}(\d{4})?)?$/
    },
    {
      name: "Lesotho",
      code: "LS",
      zipRegex: /^\d{3}$/
    },
    {
      name: "Liberia",
      code: "LR",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Libya",
      code: "LY",
      zipRegex: null
    },
    {
      name: "Liechtenstein",
      code: "LI",
      zipRegex: /^(948[5-9])|(949[0-7])$/
    },
    {
      name: "Lithuania",
      code: "LT",
      zipRegex: /^LT-\d{5}$/
    },
    {
      name: "Luxembourg",
      code: "LU",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Macao",
      code: "MO",
      zipRegex: null
    },
    {
      name: "Madagascar",
      code: "MG",
      zipRegex: /^\d{3}$/
    },
    {
      name: "Malawi",
      code: "MW",
      zipRegex: null
    },
    {
      name: "Malaysia",
      code: "MY",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Maldives",
      code: "MV",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Mali",
      code: "ML",
      zipRegex: null
    },
    {
      name: "Malta",
      code: "MT",
      zipRegex: /^[A-Z]{3}[ ]?\d{2,4}$/
    },
    {
      name: "Marshall Islands",
      code: "MH",
      zipRegex: /^969[67]\d(-\d{4})?$/
    },
    {
      name: "Martinique",
      code: "MQ",
      zipRegex: /^9[78]2\d{2}$/
    },
    {
      name: "Mauritania",
      code: "MR",
      zipRegex: null
    },
    {
      name: "Mauritius",
      code: "MU",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Mayotte",
      code: "YT",
      zipRegex: /^976\d{2}$/
    },
    {
      name: "Mexico",
      code: "MX",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Micronesia, Federated States of",
      code: "FM",
      zipRegex: /^(9694[1-4])(\d{4})?$/
    },
    {
      name: "Moldova, Republic of",
      code: "MD",
      zipRegex: /^MD-\d{4}$/
    },
    {
      name: "Monaco",
      code: "MC",
      zipRegex: /^980\d{2}$/
    },
    {
      name: "Mongolia",
      code: "MN",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Montenegro",
      code: "ME",
      zipRegex: /^8\d{4}$/
    },
    {
      name: "Montserrat",
      code: "MS",
      zipRegex: null
    },
    {
      name: "Morocco",
      code: "MA",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Mozambique",
      code: "MZ",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Myanmar",
      code: "MM",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Namibia",
      code: "NA",
      zipRegex: null
    },
    {
      name: "Nauru",
      code: "NR",
      zipRegex: null
    },
    {
      name: "Nepal",
      code: "NP",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Netherlands",
      code: "NL",
      zipRegex: /^\d{4}[ ]?[A-Z]{2}$/
    },
    {
      name: "New Caledonia",
      code: "NC",
      zipRegex: /^988\d{2}$/
    },
    {
      name: "New Zealand",
      code: "NZ",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Nicaragua",
      code: "NI",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Niger",
      code: "NE",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Nigeria",
      code: "NG",
      zipRegex: /^\d{6}$/
    },
    {
      name: "Niue",
      code: "NU",
      zipRegex: null
    },
    {
      name: "Norfolk Island",
      code: "NF",
      zipRegex: null
    },
    {
      name: "North Macedonia",
      code: "MK",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Northern Mariana Islands",
      code: "MP",
      zipRegex: /^9695[012](\d{4})?$/
    },
    {
      name: "Norway",
      code: "NO",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Oman",
      code: "OM",
      zipRegex: /^(PC )?\d{3}$/
    },
    {
      name: "Pakistan",
      code: "PK",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Palau",
      code: "PW",
      zipRegex: /^96940$/
    },
    {
      name: "Palestine, State of",
      code: "PS",
      zipRegex: null
    },
    {
      name: "Panama",
      code: "PA",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Papua New Guinea",
      code: "PG",
      zipRegex: /^\d{3}$/
    },
    {
      name: "Paraguay",
      code: "PY",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Peru",
      code: "PE",
      zipRegex: /^\d{5}$/
    },
    {
      name: "Philippines",
      code: "PH",
      zipRegex: /^\d{4}$/
    },
    {
      name: "Pitcairn",
      code: "PN",
      zipRegex: /^PCRN 1ZZ$/
    },
    {
      name: "Poland",
      code: "PL",
      zipRegex: /^\d{2}-\d{3}$/
    },
    {
      name: "Portugal",
      code: "PT",
      zipRegex: /^\d{4}-\d{3}$/
    },
    {
      name: "Puerto Rico",
      code: "PR",
      zipRegex: /^00[679]\d{2}(-\d{4})?$/
    },
    {
      name: "Qatar",
      code: "QA",
      zipRegex: null
    },
    {
      name: "Republic of North Macedonia",
      code: "MK",
      zipRegex: /\\d{4}/
    },
    {
      name: "Romania",
      code: "RO",
      zipRegex: /\\d{6}/
    },
    {
      name: "Russian Federation",
      code: "RU",
      zipRegex: /\\d{6}/
    },
    {
      name: "Rwanda",
      code: "RW",
      zipRegex: null
    },
    {
      name: "Saint Barthélemy",
      code: "BL",
      zipRegex: /97133/
    },
    {
      name: "Saint Helena, Ascension and Tristan da Cunha",
      code: "SH",
      zipRegex: /STHL 1ZZ/
    },
    {
      name: "Saint Kitts and Nevis",
      code: "KN",
      zipRegex: null
    },
    {
      name: "Saint Lucia",
      code: "LC",
      zipRegex: null
    },
    {
      name: "Saint Martin (French part)",
      code: "MF",
      zipRegex: /97150/
    },
    {
      name: "Saint Pierre and Miquelon",
      code: "PM",
      zipRegex: /97500/
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "VC",
      zipRegex: /VC\\d{4}/
    },
    {
      name: "Samoa",
      code: "WS",
      zipRegex: null
    },
    {
      name: "San Marino",
      code: "SM",
      zipRegex: /4789\\d/
    },
    {
      name: "Sao Tome and Principe",
      code: "ST",
      zipRegex: null
    },
    {
      name: "Saudi Arabia",
      code: "SA",
      zipRegex: /\\d{5}/
    },
    {
      name: "Senegal",
      code: "SN",
      zipRegex: /\\d{5}/
    },
    {
      name: "Serbia",
      code: "RS",
      zipRegex: /\\d{5,6}/
    },
    {
      name: "Seychelles",
      code: "SC",
      zipRegex: null
    },
    {
      name: "Sierra Leone",
      code: "SL",
      zipRegex: null
    },
    {
      name: "Singapore",
      code: "SG",
      zipRegex: /\\d{6}/
    },
    {
      name: "Sint Maarten (Dutch part)",
      code: "SX",
      zipRegex: null
    },
    {
      name: "Slovakia",
      code: "SK",
      zipRegex: /\\d{3} ?\\d{2}/
    },
    {
      name: "Slovenia",
      code: "SI",
      zipRegex: /\\d{4}/
    },
    {
      name: "Solomon Islands",
      code: "SB",
      zipRegex: null
    },
    {
      name: "Somalia",
      code: "SO",
      zipRegex: /[A-Z]{2} ?\\d{5}/
    },
    {
      name: "South Africa",
      code: "ZA",
      zipRegex: /\\d{4}/
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      code: "GS",
      zipRegex: /SIQQ 1ZZ/
    },
    {
      name: "South Sudan",
      code: "SS",
      zipRegex: null
    },
    {
      name: "Spain",
      code: "ES",
      zipRegex: /\\d{5}/
    },
    {
      name: "Sri Lanka",
      code: "LK",
      zipRegex: /\\d{5}/
    },
    {
      name: "Sudan",
      code: "SD",
      zipRegex: /\\d{5}/
    },
    {
      name: "Suriname",
      code: "SR",
      zipRegex: null
    },
    {
      name: "Svalbard and Jan Mayen",
      code: "SJ",
      zipRegex: /\\d{4/
    },
    {
      name: "Sweden",
      code: "SE",
      zipRegex: /\\d{3} ?\\d{2/
    },
    {
      name: "Switzerland",
      code: "CH",
      zipRegex: /\\d{4/
    },
    {
      name: "Syrian Arab Republic",
      code: "SY",
      zipRegex: null
    },
    {
      name: "Taiwan, Province of China",
      code: "TW",
      zipRegex: /\\d{3}(?:\\d{2})?/
    },
    {
      name: "Tajikistan",
      code: "TJ",
      zipRegex: /\\d{6/
    },
    {
      name: "Tanzania, United Republic of",
      code: "TZ",
      zipRegex: /\\d{5/
    },
    {
      name: "Thailand",
      code: "TH",
      zipRegex: /\\d{5/
    },
    {
      name: "Timor-Leste",
      code: "TL",
      zipRegex: null
    },
    {
      name: "Togo",
      code: "TG",
      zipRegex: null
    },
    {
      name: "Tokelau",
      code: "TK",
      zipRegex: null
    },
    {
      name: "Tonga",
      code: "TO",
      zipRegex: null
    },
    {
      name: "Trinidad and Tobago",
      code: "TT",
      zipRegex: null
    },
    {
      name: "Tunisia",
      code: "TN",
      zipRegex: /\\d{4/
    },
    {
      name: "Turkey",
      code: "TR",
      zipRegex: /\\d{5/
    },
    {
      name: "Turkmenistan",
      code: "TM",
      zipRegex: /\\d{6/
    },
    {
      name: "Turks and Caicos Islands",
      code: "TC",
      zipRegex: /TKCA 1ZZ/
    },
    {
      name: "Tuvalu",
      code: "TV",
      zipRegex: null
    },
    {
      name: "Uganda",
      code: "UG",
      zipRegex: null
    },
    {
      name: "Ukraine",
      code: "UA",
      zipRegex: /\\d{5/
    },
    {
      name: "United Arab Emirates",
      code: "AE",
      zipRegex: null
    },
    {
      name: "United States",
      code: "US",
      zipRegex: /\\d{5}(?:[-\\s]\\d{4})/
    },
    {
      name: "United States Minor Outlying Islands",
      code: "UM",
      zipRegex: null
    },
    {
      name: "Uruguay",
      code: "UY",
      zipRegex: /\\d{5/
    },
    {
      name: "Uzbekistan",
      code: "UZ",
      zipRegex: /\\d{6/
    },
    {
      name: "Vanuatu",
      code: "VU",
      zipRegex: null
    },
    {
      name: "Venezuela, Bolivarian Republic of",
      code: "VE",
      zipRegex: /\\d{4/
    },
    {
      name: "Viet Nam",
      code: "VN",
      zipRegex: /\\d{6/
    },
    {
      name: "Virgin Islands, British",
      code: "VG",
      zipRegex: /VG\\d{4/
    },
    {
      name: "Virgin Islands, U.S.",
      code: "VI",
      zipRegex: /\\d{5}(?:[-\\s]\\d{4})/
    },
    {
      name: "Wallis and Futuna",
      code: "WF",
      zipRegex: /986\\d{2/
    },
    {
      name: "Western Sahara",
      code: "EH",
      zipRegex: null
    },
    {
      name: "Yemen",
      code: "YE",
      zipRegex: null
    },
    {
      name: "Zambia",
      code: "ZM",
      zipRegex: /\\d{5/
    },
    {
      name: "Zimbabwe",
      code: "ZW",
      zipRegex: null
    },
    {
      name: "Åland Islands",
      code: "AX",
      zipRegex: /22\\d{3/
    }
  ];
  export default countries
<template>
  <div>
    <v-card flat class="grey lighten-3 rounded-md">
      <v-card-text>
        <div v-if="!$store.state.paymentDetails.IsGenericLink">
          <div class="d-flex justify-space-between">
            <div>
              <p class="text-caption my-0 font-weight-light">Account name</p>
              <p class="text-caption mt-n1 mb-1">
                {{ $store.state.paymentDetails.BankAccountName }}
              </p>
            </div>
            <div class="mb-3 text-right">
              <p class="text-caption my-0 font-weight-light">
                Payment reference
              </p>
              <p class="text-caption mt-n1">
                {{ $store.state.requestID }}
              </p>
            </div>
          </div>
          <div
            v-for="payment in $store.state.paymentDetailsToSubmit.breakDown"
            :key="payment.Id"
            class="mb-2 d-flex justify-space-between text-body-1"
          >
            <p class="my-0">{{ payment.Description }}</p>
            <p class="my-0">{{ payment.Amount.toFixed(2) }}</p>
          </div>
          <v-divider class="my-3"></v-divider>
          <div class="d-flex justify-space-between text-body-1">
            <p class="my-0 font-weight-black">Total amount</p>
            <p class="my-0 font-weight-black">
              {{ $store.state.paymentDetailsToSubmit.total | formatAmount }}
            </p>
          </div>
        </div>

        <div v-if="$store.state.paymentDetails.IsGenericLink">
          <div class="d-flex justify-space-between">
            <div>
              <p class="text-caption my-0 font-weight-light">Account name</p>
              <p class="text-caption mt-n1 mb-1">
                {{ $store.state.paymentDetails.BankAccountName }}
              </p>
            </div>
            <div class="mb-3 text-right">
              <p class="text-caption my-0 font-weight-light">
                Payment reference XXX
              </p>
              <p tabindex="0" class="text-caption mt-n1 text-right">
                {{ $store.state.requestID }}
              </p>
            </div>
          </div>
          <v-divider class="my-3"></v-divider>
          <div class="d-flex justify-space-between text-body-1">
            <p class="my-0 font-weight-black">Total amount</p>
            <p class="my-0 font-weight-black">
              {{ formatGenericLinkAmount }}
            </p>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="payment-method-title mt-6 mb-3">
      <p class="my-0 text-center text-uppercase">payment method</p>
    </div>

    <v-card outlined class="grey lighten-3 rounded-md py-3">
      <v-card-text class="py-1">
        <v-row align="center">
          <v-col>
            <div>
              <p class="text-body-1 my-0">Barclaycard ePDQ</p>
              <p class="text-caption my-0 font-weight-light">Card payment</p>
            </div>
          </v-col>
          <v-col cols="4">
            <v-img
              width="100%"
              id="barclaycardLogo"
              src="../assets/Barclaycard_Logo.svg"
              @click="dialog = true"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title class="primary py-2">
          <span class="white--text">Barclaycard ePDQ</span>
          <v-spacer></v-spacer>
          <v-btn small fab text @click="dialog = false" color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3 text-body-1">
          <p>
            <strong>ePDQ</strong> is a secure online solution by
            <strong>Barclaycard</strong> for completing payments over the
            internet.
          </p>
          <p>
            Read more about ePDQ
            <a
              target="_blank"
              href="https://www.barclaycard.co.uk/business/help/accepting-payments/what-is-epdq"
              >here.</a
            >
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'paymentCardConfirmOverview',
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style scoped>
#barclaycardLogo {
  cursor: pointer;
}
</style>
<template>
  <div>
    <div class="d-flex flex-column">
      <!-- <ThePageTitle>Give consent</ThePageTitle> -->

      <div class="payment-method-title mt-6 mb-3">
        <p class="my-0 text-center text-uppercase">payment to</p>
      </div>

      <PaymentCardConfirmOverview />

      <div class="payment-method-title mt-8">
        <p class="my-0 text-center text-uppercase">Card type</p>
      </div>
      <!-- Payment methods card -->
      <div class="mt-5 d-flex align-center justify-space-between">
        <v-btn
        v-if="$store.state.cardPaymentPermissions.CreditCardAllowed"
          @click="setCreditDebit('C')"
          width="48%"
          color="secondary"
          :outlined="creditDebit === 'C' ? false : true"
          depressed
          large
          >Credit card</v-btn
        >
        <v-btn
        v-if="$store.state.cardPaymentPermissions.DebitCardAllowed"
          @click="setCreditDebit('D')"
          width="48%"
          color="secondary"
          :outlined="creditDebit === 'D' ? false : true"
          depressed
          large
          >Debit card</v-btn
        >
      </div>

      <!-- Radio card selector based on creditDebit value -->
      <div v-if="creditDebit" class="mt-5 d-flex justify-center">
        <v-radio-group v-model="cardBrand" row>
          <v-radio class="mr-8" value="VISA">
            <template v-slot:label>
              <div>
                <v-img
                  v-if="creditDebit === 'C'"
                  class="mx-auto"
                  height="auto"
                  width="100px"
                  src="../assets/Cards/Visa_Inc._logo.svg"
                ></v-img>
                <v-img
                  v-else
                  class="mx-auto"
                  height="auto"
                  width="100px"
                  src="../assets/Cards/VisaDebit.svg"
                ></v-img>
              </div>
            </template>
          </v-radio>
          <v-radio class="mr-8" value="MasterCard">
            <template v-slot:label>
              <div>
                <v-img
                  v-if="creditDebit === 'C'"
                  class="mx-auto"
                  height="auto"
                  width="70"
                  src="../assets/Cards/Mastercard-logo.svg"
                ></v-img>
                <v-img
                  v-else
                  class="mx-auto"
                  height="auto"
                  width="70"
                  src="../assets/Cards/Debit_Mastercard.svg"
                ></v-img>
              </div>
            </template>
          </v-radio>
          <v-radio class="" v-if="creditDebit === 'D'" value="Maestro">
            <template v-slot:label>
              <div>
                <v-img
                  class="mx-auto"
                  height="auto"
                  width="70"
                  src="../assets/Cards/Maestro_2016.svg"
                ></v-img>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div class="payment-method-title mt-8">
        <p class="my-0 text-center text-uppercase">Billing Information</p>
      </div>
      <div class="">
        <v-form ref="billingInfo">
        <v-row>
          <v-col class="pb-0" cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field  v-model="billingName" label="Name *" placeholder="Your Name..." :rules="requiresValueRules"></v-text-field><br />
          </v-col>
          <v-col class="pb-0" cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field  v-model="billingEmail" label="Email Address *" placeholder="Your Email..." :rules="emailRules"></v-text-field><br />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"  lg="6" md="6" sm="12" xs="12">
            <v-text-field class="pa-0" v-model="billingAddressNumber" label="Billing House Name/Number *" :rules="requiresValueRules" placeholder="Billing House Name/Number..."></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field class="pa-0" v-model="billingAddressStreet" label="Billing Street Address *" :rules="requiresValueRules" placeholder="Billing Street Address..."></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field class="pa-0" v-model="billingAddressCity" label="Billing Town/City Address *" :rules="requiresValueRules" placeholder="Billing Address Line 3..."></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-text-field class="pa-0" v-model="billingAddressPostcode" label="Billing Postcode Address *" :rules="postcodeRules" placeholder="Billing Postcode Address..."></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <v-select v-model="selectedCountry" :items="countries" item-text="name" item-value="code" return-object class="pa-0" label="Billing Country *" ></v-select>
          </v-col>
        </v-row>
      </v-form>
      </div>

      <!-- Information paragraph  -->
      <p
        v-if="formValuesConfirmed"
        class="redirection-text text-center text-body-1 text-sm-h6 mt-12"
      >
        You will be securely redirected to
        <strong>BarclayCard`s ePDQ</strong> Card Payment application to complete
        this payment.
      </p>

      <!-- Invisible Modulr submission form -->
      <div id="form-wrapper" v-if="formValuesConfirmed">
        <form
          ref="form"
          method="post"
          :action="formURL"
          id="form1"
          name="form1"
        >
          <!-- general parameters: see Form parameters -->

          <input type="hidden" name="PSPID" :value="pspID" />

          <input type="hidden" name="ORDERID" :value="orderID" />

          <input type="hidden" name="AMOUNT" :value="formattedAmount" />

          <input type="hidden" name="CURRENCY" :value="currency" />

          <input type="hidden" name="LANGUAGE" :value="language" />

          <input type="hidden" name="CN" :value="billingName" />

          <input type="hidden" name="EMAIL" :value="billingEmail" />

          <input type="hidden" name="OWNERZIP" :value="billingAddressPostcode" />

          <input type="hidden" name="OWNERADDRESS" :value="billingAddressNumber + ' ' + billingAddressStreet" />

          <input type="hidden" name="OWNERCTY" :value="selectedCountry.code" />

          <input type="hidden" name="OWNERTOWN" :value="billingAddressCity" />

          <input type="hidden" name="OWNERTELNO" value="" />

          <!-- check before the payment: see Security: Check before the payment -->

          <input type="hidden" name="SHASIGN" :value="shaSignIn" />

          <!-- layout information: see Look and feel of the payment page -->

          <input type="hidden" name="TITLE" value="" />

          <input type="hidden" name="BGCOLOR" value="" />

          <input type="hidden" name="TXTCOLOR" value="" />

          <input type="hidden" name="TBLBGCOLOR" value="" />

          <input type="hidden" name="TBLTXTCOLOR" value="" />

          <input type="hidden" name="BUTTONBGCOLOR" value="" />

          <input type="hidden" name="BUTTONTXTCOLOR" value="" />

          <input type="hidden" name="LOGO" value="" />

          <input type="hidden" name="FONTTYPE" value="" />

          <!-- post payment redirection: see Transaction feedback to the customer -->

          <input type="hidden" name="ACCEPTURL" :value="acceptUrl" />

          <input type="hidden" name="DECLINEURL" :value="declineUrl" />

          <input type="hidden" name="EXCEPTIONURL" :value="exceptionUrl" />

          <input type="hidden" name="CANCELURL" :value="cancelUrl" />

          <!-- Card options -->

          <input type="hidden" name="PM" :value="paymentMethod" />

          <input type="hidden" name="BRAND" :value="cardBrand" />

          <input type="hidden" name="CREDITDEBIT" :value="creditDebit" />

          <input
            type="submit"
            value="go to payment"
            id="submit-btn"
            name="submit2"
          />
        </form>
      </div>

      <v-btn
        @click="hashPaymentDetails"
        v-if="!formValuesConfirmed"
        :loading="loading"
        class="mb-3 mt-5 mt-sm-8"
        x-large
        depressed
        color="primary"
        block
        :disabled="!cardIsSelected || !formIsValid"
      >
        confirm details
      </v-btn>

      <v-card flat class="rounded-md mt-10">
        <v-card-text class="py-1">
          <div class="disclaimer-textpa-2 text-center">
            <span
              >Barclaycard terms of use can be found
              <a
                target="_blank"
                href="https://www.barclaycard.co.uk/business/terms-of-use"
                >here.</a
              ></span
            >
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import PaymentCardConfirmOverview from "@/components/PaymentCardConfirmOverview.vue";
import countryList from '../jsFiles/countries.js'

export default {
  name: "PaymentCardConfirm",
  components: {
    PaymentCardConfirmOverview,
  },
  data() {
    return {
      loading: false,
      overlayLoader: false,
      formValuesConfirmed: false,
      cardTypes: [
        { title: "Debit card", subtitle: "Maestro", enum: "DEBIT" },
        { title: "Credit card", subtitle: "VISA, Mastercard", enum: "CREDIT" },
      ],
      // ePDQ values
      shaSignIn: "",
      orderID: "",
      currency: "GBP",
      language: "en_US",
      cardBrand: "",
      creditDebit: "",
      paymentMethod: "CreditCard", // never change this
      acceptUrl : '',
      declineUrl :'',
      exceptionUrl : "",
      cancelUrl : "",
      billingName : "",
      billingEmail: "",
      billingAddressNumber: "",
      billingAddressStreet: "",
      billingAddressCity: "",
      billingAddressPostcode: "",
      countries: countryList,
      selectedCountry: countryList[0],
      requiresValueRules: [
        (v) => !!v || "This is a required field."
      ],
      emailRules: [
        (v) => !!v || "This is a required field.",
        (v) => !v || /.+@.+/.test(v) || "E-mail must be valid.",
      ],
      postcodeRules: [
        (v) => !!v || "This is a required field.",
        (v) => !v || /[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}/.test(v) || "Postcode must be valid."
      ]
    };
  },
  mounted(){
    this.acceptUrl = this.redirectUrl + '/success',
    this.declineUrl = this.redirectUrl + '/decline',
    this.exceptionUrl = this.redirectUrl + '/exception',
    this.cancelUrl = this.redirectUrl + '/cancel'
    console.log(this.acceptUrl);
  },
  methods: {
    setCreditDebit(text) {
      this.cardBrand = "";
      this.creditDebit = text;
      this.$store.commit("SET_CREDIT_DEBIT", text);
    },
    hashPaymentDetails() {
      // Create payment details:-
      let paymentDetails;
      this.loading = true;
      console.log(this.email);
      if (this.$store.state.paymentDetails.IsGenericLink) {
        const submissionPaymentDetails = [
          {
            id: this.$store.state.paymentDetailsToSubmit.breakDown[0]
              .UserInputtedReference,
            amount:
              this.$store.state.paymentDetailsToSubmit.breakDown[0].Amount,
          },
        ];
        paymentDetails = submissionPaymentDetails;
      } else {
        const fullPaymentDetails =
          this.$store.state.paymentDetailsToSubmit.breakDown;
        const submissionPaymentDetails = fullPaymentDetails.map((item) => {
          return {
            id: item.ID,
            amount: item.userInputAmount,
          };
        });
        paymentDetails =  submissionPaymentDetails;
      }

      // Submission model
      const postParams = {
        body: {
          payload: { payments: paymentDetails },
          modulrBankID: this.$store.state.selectedBank.ModulrID,
          requestID: this.$store.state.requestID,
          payMethod:
            this.$store.state.epdqCreditDebit === "C"
              ? "CreditCard"
              : "DebitCard",
          initialPayment: "", // not needed on oneOff payment
          finalPayment: "", // not needed on oneOff payment
        },
      };
      //Submit to modulr:-
      API.post("paymentAppRest", "/modulr", postParams) // just posting for purpose of declaring payMethod in OS
        .then(() => {
          //After submitting modulr, then hash the payment, issues due to vuejs asynchronicity:-
          // adding a random 5 digit string to the back of the original payment reference
          // epdq does not allow the same orderID to be used within 40 odd days of each other
          const randomID = this.generateRandomID();
          // if (this.$store.state.paymentDetails.IsGenericLink) this.orderID = `${this.$store.state.requestID} ${this.$store.state.paymentDetailsToSubmit.breakDown[0].UserInputtedReference}`
          this.orderID = `${this.$store.state.requestID} ${randomID}`;

          
          const postParams = {
            body: {
              amount: this.formattedAmount,
              brand: this.cardBrand,
              creditDebit: this.creditDebit,
              currency: this.currency,
              language: this.language,
              orderID: this.orderID,
              paymentMethod: this.paymentMethod,
              pspID: this.pspID,
              email: this.billingEmail,
              acceptUrl: this.acceptUrl,
              declineUrl: this.declineUrl,
              exceptionUrl: this.exceptionUrl,
              cancelUrl: this.cancelUrl,
              name: this.billingName,
              address: this.billingAddressNumber + ' ' + this.billingAddressStreet,
              city: this.billingAddressCity,
              postcode: this.billingAddressPostcode,
              country: this.selectedCountry.code
            },
          };
          console.log(postParams);

          API.post("paymentAppRest", "/hash", postParams).then((res) => {
            this.shaSignIn = res.data.hashedString;
            this.formValuesConfirmed = true;
            this.loading = false;
          });
        });
    },
    generateRandomID() {
      // DEV test method only
      return Math.floor((1 + Math.random()) * 0x100000)
        .toString(16)
        .substring(1);
    },
  },
  computed: {
    pspID() {
      return this.$store.getters.getPSPID;
    },
    formURL(){
      return this.$store.getters.getFormUrl;
    },
    redirectUrl(){
      return this.$store.getters.getRedirectUrl;
    },
    formIsValid(){
      const validForm = this.$refs.billingInfo.validate();
      if (validForm) 
        return true;
      else
        return false;
    },
    cardIsSelected() {
      if (this.creditDebit && this.cardBrand) return true;
      return false;
    },
    formattedAmount() {
      if (this.getTotalAmount) {
        return this.getTotalAmount * 100;
      }
      return 0;
    },
    getTotalAmount() {
      if (this.$store.state.paymentDetails.IsGenericLink)
        return this.$store.state.paymentDetailsToSubmit.total;
      else {
        const amountToNumber = this.$store.state.paymentDetailsToSubmit.total;
        return amountToNumber;
      }
    },
    formatGenericLinkAmount() {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(this.$store.state.paymentDetailsToSubmit.total);
    },
  },
  watch:{
    selectedCountry(){
      console.log(this.selectedCountry.zipRegex);
      if(this.selectedCountry.zipRegex === null){
        this.postcodeRules = []
      }
      else{
        console.log(this.selectedCountry.zipRegex);
        this.postcodeRules = [
          (v) => !!v || "This is a required field.",
          (v) => !v || this.selectedCountry.zipRegex.test(v) || "Postcode must be valid."
        ]
      }
    }
  }
};
</script>

<style scoped>
.disclaimer-text {
  font-size: 10px;
  text-align: center;
  line-height: 1.3;
}

.redirection-text {
  color: #1d5fa8;
}

#submit-btn {
  background: #1d5fa8;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 3px;
  height: 52px;
  width: 100%;
  padding: 5px 8px;
  margin-top: 20px;
}
</style>
